
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.hm-document {
  &__add-btn {
    margin-bottom: $base-gutter;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $base-gutter / 2;
  }
}
