
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.modal-edit-document {
  &__fields {
    display: flex;
    flex-direction: column;
    gap: $base-gutter;
  }
}
